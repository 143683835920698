const childRoutes = (prop) => [
  {
    path: 'dashboard',
    name: prop + 'dashboard',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/Dashboard.vue')
  },
  {
    path: 'template-one',
    name: prop + 'template_one',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/template-one/List.vue')
  },
  {
    path: 'subscriber',
    name: prop + 'subscriber',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/subscriber/List.vue')
  },
  {
    path: 'admin',
    name: prop + 'admin',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/admin/List.vue')
  },
  {
    path: 'payment',
    name: prop + 'payment',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/payment/List.vue')
  },
  {
    path: 'cart',
    name: prop + 'cart',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/cart/List.vue')
  },
  {
    path: 'permission',
    name: prop + 'permission',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/user-management/permission/List.vue')
  },
  {
    path: 'role',
    name: prop + 'role',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/user-management/role/List.vue')
  },
  {
    path: 'add-or-update-role/:role_id?',
    name: prop + 'add_or_update_role',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/user-management/role/Form.vue')
  },
  {
    path: 'user',
    name: prop + 'user',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/user-management/user/List.vue')
  },
  {
    path: 'subscription-plan',
    name: prop + 'subscription_plan',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/subscription-plan/List.vue')
  },
  {
    path: 'faq',
    name: prop + 'faq',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/faq/List.vue')
  },
  {
    path: 'control-notification',
    name: prop + 'control_notification',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/control-notification/List.vue')
  },
  {
    path: 'terms-and-conditions-page',
    name: prop + 'terms_and_conditions_page',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/terms-and-conditions-page/List.vue')
  },
  {
    path: 'invoice',
    name: prop + 'invoice',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/invoice/List.vue')
  },
  {
    path: 'payment-media',
    name: prop + 'payment_media',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/payment-media/List.vue')
  },
  {
    path: 'vat-percent',
    name: prop + 'vat_percent',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/vat-percent/List.vue')
  },
  {
    path: 'book-section',
    name: prop + 'book_section',
    meta: {
      auth: true,
      name: 'Editable'
    },
    component: () => import('../pages/manage-book/section/List.vue')
  },
  {
    path: 'section-child/:id',
    name: prop + 'section_child',
    meta: {
      auth: true,
      name: 'Editable'
    },
    component: () => import('../pages/manage-book/section/sectionChild.vue')
  },
  {
    path: 'book-page',
    name: prop + 'book_page',
    meta: {
      auth: true,
      name: 'Editable'
    },
    component: () => import('../pages/manage-book/book-page/List.vue')
  },
  {
    path: 'book-content-upload/:page_no',
    name: prop + 'book_content_upload',
    meta: {
      auth: true,
      name: 'Editable'
    },
    component: () => import('../pages/manage-book/book-page/Content.vue')
    // props: true
  },
  {
    path: 'book-cover-page',
    name: prop + 'book_cover_page',
    meta: {
      auth: true,
      name: 'Editable'
    },
    component: () => import('../pages/manage-book/book-cover-page/List.vue')
  },
  {
    path: 'add-or-update-book-cover-page-content/:id?',
    name: prop + 'add_or_update_book_cover_page_content',
    meta: {
      auth: true,
      name: 'Editable'
    },
    component: () => import('../pages/manage-book/book-cover-page/Content.vue')
    // props: true
  },
  {
    path: 'section-note',
    name: prop + 'section-note',
    meta: {
      auth: true,
      name: 'Editable'
    },
    component: () => import('../pages/manage-book/section-notes/List.vue')
  },
  {
    path: 'change-password',
    name: prop + 'change_password',
    meta: {
      auth: true,
      name: 'Editable'
    },
    component: () => import('../pages/security/ChangePassword.vue')
  },
  {
    path: 'coupon',
    name: prop + 'coupon',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/coupon/List.vue')
  },
  {
    path: 'tag',
    name: prop + 'tag',
    meta: {
      auth: true,
      name: 'Editable'
    },
    component: () => import('../pages/manage-book/tag/List.vue')
  },
  {
    path: 'app-ui-features',
    name: prop + 'app_ui_features',
    meta: {
      auth: true,
      name: 'Editable'
    },
    component: () => import('../pages/app-ui-features/List.vue')
  }
]
const routes = [
  {
    path: '/',
    name: 'designer_service.configuration',
    component: () => import('@/layouts/DesignerLayout.vue'),
    // meta: { auth: true },
    children: childRoutes('')
  }
]

export default routes
